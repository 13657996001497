.App {
  text-align: center;
}

.logo {
  pointer-events: none;
  width: 120px;
  position: absolute;
  z-index: 200;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #3a2748;
  padding: 5px 10px;
}

.map {
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 100;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.leaflet-tooltip {
  max-width: 80vw;
  text-overflow: ellipsis;
  overflow: hidden;
}
